import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-holo-projection',
  templateUrl: './product-holo-projection.component.html',
  styleUrls: ['./product-holo-projection.component.scss']
})
export class ProductHoloProjectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
