import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-etc-hokkaido-drone',
  templateUrl: './product-etc-hokkaido-drone.component.html',
  styleUrls: ['./product-etc-hokkaido-drone.component.scss']
})
export class ProductEtcHokkaidoDroneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
