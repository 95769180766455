<app-nav-header></app-nav-header>
<div class="container">

  <h1>XR PRODUCTS</h1>
  <div class="mb-3">
    <app-product-vr-chainsaw></app-product-vr-chainsaw>
  </div>
  <div class="mb-3">
    <app-product-vr-grappler></app-product-vr-grappler>
  </div>
  <div class="mb-3">
    <app-product-vr-boat></app-product-vr-boat>
  </div>
  <div class="mb-3">
    <app-product-vr-gatling></app-product-vr-gatling>
  </div>
  <div class="mb-3">
    <app-product-xr-lookingcomb></app-product-xr-lookingcomb>
  </div>
  <div class="mb-3">
    <app-product-xr-nomuo></app-product-xr-nomuo>
  </div>
  <div class="mb-3">
    <app-product-holo-projection></app-product-holo-projection>
  </div>

  <h1>GAME PRODUCTS</h1>
  <div class="mb-3">
    <app-product-game-treasure-hunter></app-product-game-treasure-hunter>
  </div>
  <div class="mb-3">
    <app-product-game-chocobo></app-product-game-chocobo>
  </div>

  <h1>WEB PRODUCTS</h1>
  <div class="mb-3">
    <app-product-web-future></app-product-web-future>
  </div>
  <div class="mb-3">
    <app-product-web-acct></app-product-web-acct>
  </div>

  <h1>OTHER PRODUCTS</h1>
  <div class="mb-3">
    <app-product-etc-project-oricon></app-product-etc-project-oricon>
  </div>
  <div class="mb-3">
    <app-product-etc-hokkaido-drone></app-product-etc-hokkaido-drone>
  </div>

</div>
<app-nav-footer></app-nav-footer>

<router-outlet></router-outlet>