import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from '@angular/cdk/layout';

// components
import { AppComponent } from './app.component';
import { ProductVrChainsawComponent } from './product-vr-chainsaw/product-vr-chainsaw.component';
import { ProductVrGatlingComponent } from './product-vr-gatling/product-vr-gatling.component';
import { ProductVrGrapplerComponent } from './product-vr-grappler/product-vr-grappler.component';
import { ProductVrBoatComponent } from './product-vr-boat/product-vr-boat.component';
import { ProductHoloProjectionComponent } from './product-holo-projection/product-holo-projection.component';
import { ProductWebFutureComponent } from './product-web-future/product-web-future.component';
import { ProductWebAcctComponent } from './product-web-acct/product-web-acct.component';
import { ProductGameTreasureHunterComponent } from './product-game-treasure-hunter/product-game-treasure-hunter.component';
import { ProductGameChocoboComponent } from './product-game-chocobo/product-game-chocobo.component';
import { ProductEtcHokkaidoDroneComponent } from './product-etc-hokkaido-drone/product-etc-hokkaido-drone.component';
import { ProductXrLookingcombComponent } from './product-xr-lookingcomb/product-xr-lookingcomb.component';
import { ProductEtcProjectOriconComponent } from './product-etc-project-oricon/product-etc-project-oricon.component';
import { NavHeaderComponent } from './nav-header/nav-header.component';
import { NavFooterComponent } from './nav-footer/nav-footer.component';
import { ProductXrNomuoComponent } from './product-xr-nomuo/product-xr-nomuo.component';

@NgModule({
  declarations: [
    AppComponent,
    ProductVrChainsawComponent,
    ProductVrGatlingComponent,
    ProductVrGrapplerComponent,
    ProductVrBoatComponent,
    ProductHoloProjectionComponent,
    ProductWebFutureComponent,
    ProductWebAcctComponent,
    ProductGameTreasureHunterComponent,
    ProductGameChocoboComponent,
    ProductEtcHokkaidoDroneComponent,
    ProductXrLookingcombComponent,
    ProductEtcProjectOriconComponent,
    NavHeaderComponent,
    NavFooterComponent,
    ProductXrNomuoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
