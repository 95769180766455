import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-vr-chainsaw',
  templateUrl: './product-vr-chainsaw.component.html',
  styleUrls: ['./product-vr-chainsaw.component.scss']
})
export class ProductVrChainsawComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
