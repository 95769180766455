<nav class="navbar navbar-light bg-light">
  <div class="container">
    <dl class="row">
      <dt class="col-sm-3">会社名</dt>
      <dd class="col-sm-9">株式会社デッドロック</dd>

      <dt class="col-sm-3">事業内容</dt>
      <dd class="col-sm-9">
        <div>ゲーム制作</div>
        <div>ソフトウェア制作</div> 
        <div>ウェブサイト構築</div>
      </dd>

      <dt class="col-sm-3">営業所</dt>
      <dd class="col-sm-9">
        <div>〒003-0004</div>
        <div>北海道札幌市白石区東札幌4条6丁目4番12号 メモリアル88ハイツ 603</div>
      </dd>

      <dt class="col-sm-3">設立</dt>
      <dd class="col-sm-9">2010年7月26日</dd>

      <dt class="col-sm-3">資本金</dt>
      <dd class="col-sm-9">68,000円</dd>

      <dt class="col-sm-3">役員</dt>
      <dd class="col-sm-9">代表取締役 大山 環</dd>
    </dl>
    <div class="row">
      <div class="text-right">Copyright © 2020 DEADLOCK,Inc.</div>
    </div>
  </div>
</nav>
