<div class="card">
  <div class="card-body">
    <h5 class="card-title">チョコボの不思議なダンジョン エブリバディ！</h5>
    <h6 class="card-subtitle mb-2 text-muted">© SQUARE ENIX CO., LTD.</h6>
    <p class="card-text">
    </p>
    <dl class="row">
      <dt class="col-sm-3">制作年</dt>
      <dd class="col-sm-9">2019</dd>

      <dt class="col-sm-3">担当</dt>
      <dd class="col-sm-9">街関連プログラム</dd>

      <dt class="col-sm-3">プラットホーム</dt>
      <dd class="col-sm-9">Nintendo Switch</dd>
    </dl>
    <a href="https://www.jp.square-enix.com/chocobo/" class="btn btn-primary">製品情報</a>
  </div>
</div>
