<div class="card">
  <div class="card-body">
    <h5 class="card-title">蹴落とし！トレジャーハンター！</h5>
    <h6 class="card-subtitle mb-2 text-muted">© WINGLAY</h6>
    <p class="card-text">
    </p>
    <dl class="row">
      <dt class="col-sm-3">制作年</dt>
      <dd class="col-sm-9">2019</dd>
    
      <dt class="col-sm-3">プラットホーム</dt>
      <dd class="col-sm-9">Nintendo Switch</dd>
    </dl>
    <a href="http://www.winglay.com/kth/" class="btn btn-primary">製品情報</a>
  </div>
</div>
