import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-vr-boat',
  templateUrl: './product-vr-boat.component.html',
  styleUrls: ['./product-vr-boat.component.scss']
})
export class ProductVrBoatComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
