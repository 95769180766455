<div class="card">
  <div class="card-body">
    <h5 class="card-title">重機系 VTuber 重油のむ夫</h5>
    <h6 class="card-subtitle mb-2 text-muted">VTuberハッカソン2018 北海道</h6>
    <p class="card-text">
    </p>
    <dl class="row">
      <dt class="col-sm-3">制作年</dt>
      <dd class="col-sm-9">2018</dd>
    
      <dt class="col-sm-3">プラットホーム</dt>
      <dd class="col-sm-9">Windows</dd>
    </dl>
  </div>
</div>
