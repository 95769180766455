<div class="card">
  <div class="card-body">
    <h5 class="card-title">Salmon Boat</h5>
    <h6 class="card-subtitle mb-2 text-muted">TEST PRODUCT</h6>
    <p class="card-text">
    </p>
    <dl class="row">
      <dt class="col-sm-3">制作年</dt>
      <dd class="col-sm-9">2019</dd>
    
      <dt class="col-sm-3">プラットホーム</dt>
      <dd class="col-sm-9">Oculus Quest</dd>
    </dl>
  </div>
</div>
