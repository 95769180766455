<div class="card">
  <div class="card-body">
    <h5 class="card-title">ACCT</h5>
    <h6 class="card-subtitle mb-2 text-muted">© 日本アパレルソーイング工業組合連合会</h6>
    <p class="card-text">
    </p>
    <dl class="row">
      <dt class="col-sm-3">制作年</dt>
      <dd class="col-sm-9">2018</dd>
    
      <dt class="col-sm-3">プラットホーム</dt>
      <dd class="col-sm-9">CakePHP / Anguler</dd>
    </dl>
    <a href="https://acct.yuka-alpha.net/" class="btn btn-primary">製品情報</a>
  </div>
</div>
