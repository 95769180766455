import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-xr-nomuo',
  templateUrl: './product-xr-nomuo.component.html',
  styleUrls: ['./product-xr-nomuo.component.scss']
})
export class ProductXrNomuoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
