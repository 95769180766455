import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-etc-project-oricon',
  templateUrl: './product-etc-project-oricon.component.html',
  styleUrls: ['./product-etc-project-oricon.component.scss']
})
export class ProductEtcProjectOriconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
